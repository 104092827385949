/*estilo carrosel preços */
.bg-special-color {
  background-color: #fff;
}

.text-special-text-color {
  color: #000;
}

.carousel__slide .special-card .border {
  border-color: #000000;
}


/* .carousel__slide .special-card button {
  background-color: black;
  color: #fff;
}

.carousel__slide button:not(.special-card) {
  background-color: #fff;
  color: #000;
} */
