@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Open Sans', sans-serif;
    overflow-x: hidden;
  }

  .paralelograma {
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
  }

  .paralelograma2 {
    clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
  }

  

  .paralelograma3 {
    clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
  }


  @media (min-width: 768px) {
    .MDparalelograma {
      clip-path: polygon(3% 0, 100% 0%, 98% 100%, 0% 100%);
    }
    }
  
  
  .slide-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
  }
  
  .slide-down {
    max-height: 500px;
  }
  
  .slide-up {
    max-height: 0;
  }