.slide-container {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease-in-out;
  }
  
  .slide-down {
    max-height: 700px; /* Adjust the desired max height of the container */
  }
  
  .slide-up {
    max-height: 0;
    max-width: 0;
  }