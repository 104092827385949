@media screen and (max-width: 640px) {

/* .swiper {
    margin-left: 0px;
    display: flex;
    align-content: center;
    
} */

.slide-item {
    display: flex;
    align-items: center;
/*     margin-left: 25px;
    margin-right: 25px; */
    /* margin: 0px;
    padding: 20px;
    height: 100px;
    width: 100px;   */
}

.slide-item1 {
    padding-left: 20px;
    
}

.slide-item2 {
    padding-right: 20px;
}

.slide-item img {
    
/*     height: 100%;
    width: 100%; */
    
}

}

.swiper-pagination {
    padding-top: 8px;
}


/* .swiper {
    height: 20vh;
}

.slide-item {
    display: flex;
    
}

.slide-item img {
    height: 100%;
    object-fit: cover;
    width: 100%;
} */

.swiper-pagination-bullet-active {
    background-color: #4C4260;
    opacity: 1; 
}

.swiper-button-prev {
    color: #4C4260;
}

.swiper-button-next {
    color:#4C4260;
}
